import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useBlog, useListPost } from "../../hooks/methods/blog";
import Layout from "../../templates/MainTemplate";

import "moment/locale/pt-br";
import { RiCalendarCheckLine, RiAccountCircleLine } from "react-icons/ri";
import { BsShare, BsWhatsapp, BsLinkedin, BsFacebook } from "react-icons/bs";
import Seo from "../../components/Seo";
import {
  ContainerPost,
  ContentPost,
  FooterMostContent,
  LineSeparator,
  ListMoreContent,
  MetaPost,
} from "../../view/blog/styledPost";
import { CardPost } from "../../components/CardPost/cardPost";
import { Dropdown } from "../../components/Dropdown/dropdown";
import { ReadingBar } from "../../components/Readingbar/readingBar";

export default function Post({ params, pageContext }) {
  const [open, setOpen] = useState(false);
  const { data: listPosts, isSuccess } = useListPost("date_DESC", 3, 0);
  const htmlElement = useRef(null);

  const slug =
    typeof window !== "undefined" && window?.location?.pathname?.split("/")[2];
  const { data, redirect } = useBlog(slug);

  useEffect(() => {
    if (data?.post?.content?.html) {
      clickableSummary();
    }
  }, [data?.post?.content?.html]);

  if (redirect) {
    window.location.href = redirect;

    return <></>;
  }

  const url = `https://cinnecta.com/conteudos/${slug}`;
  const socialShareMap = {
    whatsapp: {
      shareUrl: `https://api.whatsapp.com/send?text=http%3A%2F%2Fcinnecta.com%2Fconteudos%2F${slug}`,
      display: <BsWhatsapp color="var(--blue-50)" size="20" />,
    },
    linkedin: {
      shareUrl: "https://www.linkedin.com/shareArticle?mini=true&url=",
      display: <BsLinkedin color="var(--blue-50)" size="20" />,
    },
    facebook: {
      shareUrl: "https://www.facebook.com/sharer/sharer.php?u=",
      display: <BsFacebook color="var(--blue-50)" size="20" />,
    },
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const socialRedirect = () => {
    return Object.entries(socialShareMap).map(([key, value]) => {
      if (key === "whatsapp") {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            key={key}
            href={`${value.shareUrl}`}
          >
            {value.display}
          </a>
        );
      } else {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            key={key}
            href={`${value.shareUrl}${url}`}
          >
            {value.display}
          </a>
        );
      }
    });
  };

  const treatImageLink = (htmlContent) => {
    if (htmlContent && data?.post.linkImages?.length > 0) {
      const images = data.post.linkImages.map((linkImage) => {
        const imageStartIdentifier = `<img src="${linkImage.url}"`;
        const imageEndIdentifier = "/>";
        const imageStartPosition = htmlContent.indexOf(imageStartIdentifier);
        const imageEndPosition = htmlContent.indexOf(
          imageEndIdentifier,
          imageStartPosition
        );

        let imageTag;

        if (imageStartPosition !== -1) {
          imageTag = htmlContent.substring(
            imageStartPosition,
            imageEndPosition + imageEndIdentifier.length
          );
        }

        return {
          imageLink: linkImage.imageLink,
          imageTag,
        };
      });

      images.forEach(({ imageLink, imageTag }) => {
        if (imageTag) {
          htmlContent = htmlContent.replace(
            imageTag,
            `<a href="${imageLink}" target="_blank">${imageTag}</a>`
          );
        }
      });
    }

    return htmlContent;
  };

  const nameAuthor = data?.post?.author?.name
    ? data?.post?.author?.name
    : "Cinnecta";

  const html = treatImageLink(data?.post?.content?.html);

  function clickableSummary() {
    const summaryElement = htmlElement.current.querySelectorAll("li");
    const arrSummaryElements = [...summaryElement];
    const anchors = htmlElement.current.querySelectorAll("h2");
    const arrAnchors = [...anchors];
    return arrAnchors?.forEach((el, i) => {
      if (arrSummaryElements[i]?.firstChild.firstChild?.hash) {
        el.setAttribute(
          "id",
          arrSummaryElements[i].firstChild.firstChild.hash.replace(/#/, "")
        );
      }
    });
  }

  return (
    <Layout>
      {data?.post ? (
        <>
          <ReadingBar title={data?.post?.title} slug={slug} />
          <ContainerPost>
            <h1>{data?.post?.title}</h1>
            <MetaPost>
              <span>
                <RiCalendarCheckLine color="var(--text)" />
                {moment(data?.post?.date)
                  .locale("pt-br")
                  .format("LL")}
              </span>
              <span>
                <RiAccountCircleLine color="var(--text)" />
                {nameAuthor}
              </span>
              <Dropdown
                open={open}
                trigger={
                  <BsShare
                    onClick={handleOpen}
                    color="var(--blue-50)"
                    size="20"
                  />
                }
                menu={socialRedirect()}
              />
            </MetaPost>
            <LineSeparator />
            <ContentPost
              ref={htmlElement}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </ContainerPost>
          <FooterMostContent>
            <p>Confira mais conteúdos</p>
            <ListMoreContent>
              {isSuccess &&
                listPosts?.posts?.map(
                  ({ slug, title, coverImage, category, form }) => {
                    return (
                      <CardPost
                        key={slug}
                        noExcerpt
                        post={{ slug, title, coverImage, category, form }}
                      />
                    );
                  }
                )}
            </ListMoreContent>
          </FooterMostContent>
        </>
      ) : null}
    </Layout>
  );
}

export const Head = ({ pageContext }) => {
  return (
    <Seo
      title={pageContext?.title}
      description={pageContext?.description}
      url={pageContext?.coverImage}
      robots={pageContext?.robots}
      keywords={pageContext?.keywords}
    />
  );
};
